import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

import LavoraConNoiImg from '../images/lavora_con_noi.jpg'

const LavoraConNoi = () => {
  return (
    <Layout>
      <SEO
        title="Lavora con noi"
        description="Lavora per corriere GLS Sicilia, sedi di Catania, Messina, Milazzo, Ragusa e Siracusa"
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          Lavora con noi
        </h1>
      </section>
      <section className="py-12 px-12 bg-white lg:rounded-lg shadow-xl">
        <div className="flex flex-row flex-wrap items-start justify-between">
          <div className="pr-12 w-full lg:w-2/3">
            <p className="mb-6">
              Vuoi lavorare per un'azienda dinamica e in crescita? Puoi unirti a
              GLS, uno dei principali corrieri espresso in Europa. I nostri
              dipendenti sono il nostro potenziale. Il nostro potenziale siete
              voi. Scopri le opportunità di lavoro in GLS e proiettati verso una
              carriera vantaggiosa.
            </p>
            <p className="mb-6">
              Al momento non abbiamo posizioni aperte, ma, se sei interessato a
              lavorare con noi, puoi comunque inviarci una candidatura spontanea
              all'indirizzo email{' '}
              <a
                href="mailto:hr@varfinsrl.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gls-blue-600 hover:text-gls-blue-900 hover:underline"
              >
                hr@varfinsrl.com
              </a>
              .
            </p>
          </div>
          <div className="w-full lg:w-1/3">
            <img
              src={LavoraConNoiImg}
              alt="Lavora con noi"
              className="w-full rounded-lg"
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LavoraConNoi
